import { initializeApp, getApps, getApp } from "firebase/app";
import {
  Analytics,
  getAnalytics,
  setAnalyticsCollectionEnabled,
} from "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};
let app;
let analytics: Analytics;
// Initialize Firebase
if (!getApps().length) {
  app = initializeApp(firebaseConfig);
  if (typeof window !== "undefined") {
    analytics = getAnalytics(app);
    if (process.env.NODE_ENV !== "production") {
      setAnalyticsCollectionEnabled(analytics, false);
    }
  }
} else {
  app = getApp();
}
analytics = getAnalytics(app);
export { analytics };
